<template>
  <div class="contents" id="contents">
    <div class="inner-lg">
      <section class="title-section">
        <div class="title-wrap">
          <h2 class="title">EVENT HALL</h2>
          <span class="title-desc">{{$t('content.event.EventReservation.txt01')}}</span>
        </div>
      </section>
      <!-- Event Hall Reservation Section -->
      <section class="event-reservation-section event-hall">
        <h3 class="center">Main Hall</h3>
        <div class="event-reservation-wrap">
          <div class="image-area" data-aos="fade-right">
            <!-- 이미지 슬라이더 -->
            <BaseSlider
              :list="mainHallList"
              :autoplay='false'
              :navigation="true"
              :pagination="true"
            >
              <template v-slot="{ item }">
                <img :src="item" :alt="$t('content.event.EventReservation.txt02')" />
              </template>
            </BaseSlider>
            <!-- // 이미지 슬라이더 -->
          </div>
          <div class="info-area" data-aos="fade-left">
            <!-- 이용 안내 -->
            <div class="info-use-header">
              <span class="fee" v-html="$t('content.event.EventReservation.txt03')"></span>
            </div>
            <ul class="info-use">
              <li>
                <h4>{{$t('content.event.EventReservation.txt04')}}</h4>
                <ul class="bullet-square-list">
                  <li>{{$t('content.event.EventReservation.txt05')}}</li>
                  <li>{{$t('content.event.EventReservation.txt06')}}</li>
                </ul>
              </li>
              <li>
                <h4>{{$t('content.event.EventReservation.txt07')}}</h4>
                <em v-html="$t('content.event.EventReservation.txt08')"></em>
                <ul class="bullet-square-list">
                  <!-- <li>{{$t('content.event.EventReservation.txt09')}}</li> -->
                  <!-- <span class="asterisk-mark">{{$t('content.event.EventReservation.txt10')}}</span> -->
                  <li v-html="$t('content.event.EventReservation.txt11')">

                  </li>
                </ul>
              </li>
              <li>
                <h4>{{$t('content.event.EventReservation.txt12')}}</h4>
                <ul class="bullet-square-list">
                  <li>{{$t('content.event.EventReservation.txt13')}}</li>
                  <li v-html="$t('content.event.EventReservation.txt14')">

                  </li>
                  <li>{{$t('content.event.EventReservation.txt15')}}</li>
                  <li v-html="$t('content.event.EventReservation.txt16')">

                  </li>
                </ul>
              </li>
            </ul>
            <!-- // 이용 안내 -->
          </div>
        </div>
      </section>
      <!-- // Event Hall Section -->
      <!-- Multi Room Section -->
      <section class="event-reservation-section multi-room">
        <h3 class="center">Multi Room</h3>
        <!-- Tab -->
        <BaseTab
          v-model="tabValue"
          v-bind:list="multiRoom"
          tabtype="small-tab"
          @input="tabCallback"
          >
          <template v-slot="Scope">
            <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
          </template>
        </BaseTab>
        <!-- // Tab -->
        <!-- Multi Room -->
        <div class="multi-room-area">
          <BaseSlider class="s2"
            ref="multiRoomSlider"
            width='1400'
            :autoplay='false'
            :list="multiRoomList"
            :pagination="false"
            :navigation="false"
            @change="onChangeMultiRoom"
            >
            <template v-slot="{ item }">
              <div class="event-reservation-wrap multi-room">
                <!-- 이미지 영역-->
                <div class="image-area" data-aos="fade-right">
                  <img :src="item.img" :alt="`${item.title} 이미지`" />
                </div>
                <!-- // 이미지 영역 -->
                <!-- 이용 안내 -->
                <div v-html="item.content">
                </div>
                <!-- // 이용 안내 -->
              </div>
            </template>
          </BaseSlider>
          <!-- // Swiper -->
        </div>
        <!-- // Multi Room -->
      </section>
      <!-- // Multi Room Section -->
    </div>
    <!-- 대관 방법 -->
    <section class="guide-section">
      <div class="inner-md">
        <h3 class="smaller">{{$t('content.event.EventReservation.txt17')}}</h3>
        <ol class="guide-list ani-stagger">
          <li>
            <span class="icon calendar"></span>
            <dl>
              <dt>{{$t('content.event.EventReservation.txt18')}}</dt>
              <dd v-html="$t('content.event.EventReservation.txt19')">

              </dd>
            </dl>
          </li>
          <li>
            <span class="icon document"></span>
            <dl>
              <dt>{{$t('content.event.EventReservation.txt20')}}</dt>
              <dd v-html="$t('content.event.EventReservation.txt21')">

              </dd>
            </dl>
          </li>
          <li>
            <span class="icon successful"></span>
            <dl>
              <dt>{{$t('content.event.EventReservation.txt22')}}</dt>
              <dd>{{$t('content.event.EventReservation.txt23')}}</dd>
            </dl>
          </li>
          <li>
            <span class="icon investor"></span>
            <dl>
              <dt>{{$t('content.event.EventReservation.txt24')}}</dt>
              <dd v-html="$t('content.event.EventReservation.txt25')">

              </dd>
            </dl>
          </li>
          <li>
            <span class="icon event"></span>
            <dl>
              <dt>{{$t('content.event.EventReservation.txt26')}}</dt>
              <dd v-html="$t('content.event.EventReservation.txt27')">
              </dd>
            </dl>
          </li>
          <li>
            <span class="icon ps"></span>
            <dl>
              <dt>{{$t('content.event.EventReservation.txt28')}}</dt>
              <dd v-html="$t('content.event.EventReservation.txt29')">
              </dd>
            </dl>
          </li>
        </ol>
      </div>
    </section>
    <!-- // 대관 방법 -->
    <!-- 주의 사항 -->
    <section class="cautions-section" data-aos="fade-up">
      <div class="inner-md">
        <div class="title-wrap">
          <h6 class="h6-20 icon-notice">{{$t('content.event.EventReservation.txt30')}}</h6>
          <p class="desc-text">
            {{$t('content.event.EventReservation.txt31')}}
            <a href="#" class="underline-text" @click.prevent="onFacilitiesPopup">
              <strong>{{$t('content.event.EventReservation.txt32')}}</strong>
            </a> {{$t('content.event.EventReservation.txt33')}}
          </p>
        </div>
        <dl class="bullet-circle-list">
          <dt>{{$t('content.event.EventReservation.txt34')}}</dt>
          <dd>
            <ul>
              <li>{{$t('content.event.EventReservation.txt35')}}</li>
              <li>
                {{$t('content.event.EventReservation.txt36')}}
                <br />
                <span class="space">{{$t('content.event.EventReservation.txt37')}}</span>
              </li>
            </ul>
          </dd>
          <dt>{{$t('content.event.EventReservation.txt38')}}</dt>
          <dd>{{$t('content.event.EventReservation.txt39')}}</dd>
          <dt>{{$t('content.event.EventReservation.txt40')}}</dt>
          <dd>{{$t('content.event.EventReservation.txt41')}}</dd>
          <dt>{{$t('content.event.EventReservation.txt42')}}</dt>
          <dd>
            <ul>
              <li>{{$t('content.event.EventReservation.txt43')}}</li>
              <li>{{$t('content.event.EventReservation.txt44')}}</li>
              <li>{{$t('content.event.EventReservation.txt45')}}</li>
              <li v-html="$t('content.event.EventReservation.txt46')"></li>
              <li>{{$t('content.event.EventReservation.txt47')}}</li>
            </ul>
          </dd>
          <div class="reference-mark">
            <span>{{$t('content.event.EventReservation.txt50')}}</span>
            <ul>
              <li>{{$t('content.event.EventReservation.txt51')}}</li>
              <li>{{$t('content.event.EventReservation.txt52')}}</li>
              <li>{{$t('content.event.EventReservation.txt53')}}</li>
            </ul>
          </div>
        </dl>
      </div>
    </section>
    <!-- // 주의 사항 -->
    <!-- 대관 신청 문의 -->
    <section class="contact-section" data-aos="fade-up">
      <div class="inner-md">
        <h4>{{$t('content.event.EventReservation.txt54')}}</h4>
        <div class="contact-list-wrap">
          <dl class="bullet-circle-list">
            <dd>
              <span>{{$t('content.event.EventReservation.txt56')}}</span>
              <ExternalLink
                :to="`mailto:${$t('content.event.EventReservation.txt56_2')}`"
              ><span> {{$t('content.event.EventReservation.txt56_2')}}</span></ExternalLink>
              <span>{{$t('content.event.EventReservation.txt56_3')}}</span>
            </dd>
          </dl>
        </div>
      </div>
    </section>
    <!-- // 대관 신청 문의 -->
    <!-- 버튼 -->
    <div class="btn-wrap">
      <button class="btn-basic" @click.prevent="goNext">
        {{$t('content.event.EventReservation.txt59')}}
      </button>
    </div>
    <!-- // 버튼 -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import BaseSlider from '@/components/slider/BaseSlider.vue';
import BaseTab from '@/components/base/BaseTab.vue';
import ExternalLink from '@/components/base/ExternalLink.vue';
import Facilities from '@/views/legal/Facilities.vue';

export default {
  name: 'Reservation',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    BaseSlider,
    BaseTab,
    ExternalLink,
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      tabValue: 0,
      multiRoomSlider: undefined,
      multiRoomSliderIndex: undefined,
      mainHallList: [this.requireAssetsImage('event/MainHall_01.jpg'),
        this.requireAssetsImage('event/MainHall_02.jpg'),
        this.requireAssetsImage('event/MainHall_03.jpg'),
        this.requireAssetsImage('event/MainHall_04.jpg'),
      ],
      multiRoomList: [
        {
          img: this.requireAssetsImage('event/Multi_A_01.jpg'),
          title: 'Multi Room A',
          content: this.$t('content.event.EventReservation.txt60'),
        },
        {
          img: this.requireAssetsImage('event/Multi_B_01.jpg'),
          title: 'Multi Room B',
          content: this.$t('content.event.EventReservation.txt61'),
        },
        {
          img: this.requireAssetsImage('event/Multi_C_01.jpg'),
          title: 'Multi Room C',
          content: this.$t('content.event.EventReservation.txt62'),
        },
        {
          img: this.requireAssetsImage('event/Multi_BC_01.jpg'),
          title: 'Multi Room B+C',
          content: this.$t('content.event.EventReservation.txt63'),
        },
        {
          img: this.requireAssetsImage('event/Multi_D_01.jpg'),
          title: 'Multi Room D',
          content: this.$t('content.event.EventReservation.txt64'),
        },
        {
          img: this.requireAssetsImage('event/Multi_E_01.jpg'),
          title: 'Multi Room E',
          content: this.$t('content.event.EventReservation.txt65'),
        },
        {
          img: this.requireAssetsImage('event/Multi_DE_01.jpg'),
          title: 'Multi Room D+E',
          content: this.$t('content.event.EventReservation.txt66'),
        },
      ],
    };
  },
  mounted() {
    this.multiRoomSlider = this.$refs.multiRoomSlider;
  },
  computed: {
    multiRoom() {
      return this.multiRoomList.reduce((all, item) => all.concat(item.title), []);
    },
  },
  methods: {
    onChangeMultiRoom(v) {
      this.tabValue = v;
    },
    tabCallback() {
      const tmp = this.tabValue + 1;
      this.multiRoomSlider.$el.swiper.slideTo(tmp);
    },
    onFacilitiesPopup() {
      this.showPopup(Facilities, { width: '800px' });
    },
    goNext() {
      const userInfo = this.$store.getters.getUserInfo();
      if (userInfo) {
        this.$router.push({ path: '/reservation/application' });
      } else {
        this.goLoginPage()
      }
    },
    goLoginPage() {
      const buttons = [{
        title: this.$t('content.event.Event.txt11'),
        type: 'line',
        class: '',
        handler: () => {
          this.$modal.hide('dialog');
        },
      }, {
        title: this.$t('content.event.Event.txt12'),
        default: true,
        class: '',
        handler: () => {
          this.$router.push({
            path: '/reservation/application'
          });
        },
      }];
      this.showConfirm(this.$t('confirmMessage.requiredLogin'), {
        buttons: buttons
      });
    }
  },
};
</script>

<style scoped>
event-reservation-wrap .info-area {
    width: initial;
}
</style>
